import React, { useState } from "react";

export default function Tooltip({ text }) {
  const [showTooltip, setShowTooltip] = useState(false);

  /** 設定されたメッセージに改行コードがあった場合に改行する */
  const MultiLineBody = ({ body }) => {
    const texts = body.split("\n").map((item, index) => {
      return (
        <React.Fragment key={index}>
          {item}
          <br />
        </React.Fragment>
      );
    });
    return <div>{texts}</div>;
  };

  const handleCellClick = () => {
    setShowTooltip(!showTooltip);
  };

  const handleTooltipClick = (e) => {
    e.stopPropagation();
    setShowTooltip(false);
  };

  return (
    <>
      {text && (
        <div
          className="relative cursor-pointer"
          onClick={handleCellClick}
          style={{
            maxWidth: "100%",
          }}
        >
          {showTooltip && (
            <div
              className={`absolute z-50 p-2`}
              style={{
                backgroundColor: "#f0f0f0",
                color: "#333",
                padding: "8px",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                whiteSpace: "normal",
                wordBreak: "break-all",
              }}
              onClick={handleTooltipClick}
            >
              <MultiLineBody body={text} />
            </div>
          )}
          <div className="truncate hover:underline cursor-pointer">{text}</div>
        </div>
      )}
    </>
  );
}
