import {
  getAlertDataApi,
  updateAlertDataApi,
  uploadAlertListApi,
} from "./aws-config";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";

/* APIGateway経由でDynamoDBのデータを取得する */
export const getAlertData = async (params) => {
  // idトークンの取得
  const session = await fetchAuthSession();
  const idToken = session.tokens?.idToken?.toString();
  const url = new URL(getAlertDataApi);
  Object.keys(params).forEach((key) => {
    url.searchParams.append(key, params[key]);
  });
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!response) {
      throw new Error("No response");
    } else if (!response.ok && response.status === 401) {
      // トークンの期限切れ等で認証情報エラーが発生した場合
      throw new Error("UNAUTHORIZED");
    } else if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message || "HTTP error. status: " + response.statusText
      );
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

/* APIGateway経由でDynamoDBのデータを更新する */
export const updateAlertData = async (item, status) => {
  // idトークンの取得
  const session = await fetchAuthSession();
  const idToken = session.tokens?.idToken?.toString();
  // cognitoのユーザ情報を取得
  const user = await getCurrentUser();
  const url = new URL(updateAlertDataApi);
  const params = {
    id: item.id,
    mail_received_date: item.mail_received_date,
    status: status,
    update_user: user?.signInDetails?.loginId,
  };
  const body = JSON.stringify(params);
  try {
    const response = await fetch(url, {
      method: "POST",
      body: body,
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!response) {
      throw new Error("No response.");
    } else if (!response.ok && response.status === 401) {
      // トークンの期限切れ等で認証情報エラーが発生した場合
      throw new Error("UNAUTHORIZED");
    } else if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message || "HTTP error. status: " + response.statusText
      );
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updateing data:", error);
    throw error;
  }
};

/* APIGateway経由でS3に警報リストを保存する */
export const uploadAlertList = async (file) => {
  try {
    // idトークンの取得
    const session = await fetchAuthSession();
    const idToken = session.tokens?.idToken?.toString();
    // 署名付きURLの取得
    const resSignedUrl = await fetch(uploadAlertListApi, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!resSignedUrl) {
      throw new Error("No response.");
    } else if (!resSignedUrl.ok && resSignedUrl.status === 401) {
      // トークンの期限切れ等で認証情報エラーが発生した場合
      throw new Error("UNAUTHORIZED");
    } else if (!resSignedUrl.ok) {
      const errorData = await resSignedUrl.json();
      throw new Error(
        errorData.message || "HTTP error. status: " + resSignedUrl.statusText
      );
    }

    const { url } = await resSignedUrl.json();

    // S3へのファイルアップロード
    const res = await fetch(url, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
      },
    });
    if (!res) {
      throw new Error("No response.");
    } else if (!res.ok) {
      const errorData = await res.json();
      throw new Error(
        errorData.message || "HTTP error. status: " + res.statusText
      );
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};
