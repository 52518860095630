import React, { useState } from "react";
import { uploadAlertList } from "../../dataService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/** 警報リストアップロードのモーダルコンポーネント */
const UploadModal = (props) => {
  const [file, setFile] = useState(null);

  /** モーダルを閉じる */
  const closeModal = () => {
    setFile();
    props.setOpen(false);
  };

  /** 入力フォームにファイルが設定された時の処理 */
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  /** S3へファイルをアップロードする */
  const handleSubmit = async (event) => {
    props.setOpen(false);
    event.preventDefault();
    if (!file) return;

    try {
      await uploadAlertList(file);
      toast.success("警報リストをアップロードしました。");
    } catch (error) {
      if (error.message === "UNAUTHORIZED") {
        // 認証情報エラーになった場合、ユーザの認証情報を削除してサインアウト状態にする
        props.signOut();
      } else {
        props.setError(error);
      }
    }
    setFile();
  };
  return (
    <>
      {props.open ? (
        <dialog
          id="my_modal_1"
          className="modal"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
          }}
          open={props.open}
        >
          <form
            method="dialog"
            className="modal-box h-[300px] min-w-[25%] relative flex flex-col"
          >
            <div className="m-3 flex flex-col h-full overflow-hidden">
              <div className="overflow-hidden">
                <h3 className="font-bold text-lg">警報リストアップロード</h3>
                <div className="m-3">
                  警報リスト(CSVファイル)を指定しアップロードしてください。
                </div>
                <input
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                  className="max-sm:mt-2 sm:mt-5 file-input file-input-bordered file-input-primary w-full max-w-md"
                />
              </div>
            </div>
            <div className="modal-action absolute bottom-0 left-0 right-0 p-4 bg-base-100 border-t border-gray-300">
              <button className="btn" onClick={closeModal}>
                閉じる
              </button>
              <button
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={!file}
              >
                アップロード
              </button>
            </div>
          </form>
        </dialog>
      ) : (
        <></>
      )}
    </>
  );
};

export default UploadModal;
