import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import { confirmSignIn } from "aws-amplify/auth";
import { translations, Authenticator } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//　Amplify UIの日本語化対応
const customTranslations = {
  ja: {
    "Setup TOTP": "認証コードの確認",
    "Confirm TOTP Code": "認証コードの確認",
    "Incorrect username or password.":
      "ユーザー名またはパスワードが間違っています",
    "Enter your email": "メールアドレスを入力",
    "Code *": "認証コード",
    "Password must have at least 8 characters": "パスワードは8文字以上必要です",
    "Your passwords must match": "パスワードが一致しません",
    "Invalid code received for user": "無効な認証コードが入力されました",
    "Invalid session for the user, session is expired.":
      "セッションの有効期限が切れています",
    "Code mismatch": "認証コードが一致しません",
  },
};

I18n.putVocabularies(translations);
I18n.putVocabularies(customTranslations);
I18n.setLanguage("ja");

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_AWC_COGNIT_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_AWC_COGNIT_CLIENT_ID,
      loginWith: {
        email: true,
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <Authenticator
        hideSignUp={true}
        setLanguage={"ja"}
        services={{
          async handleConfirmSignIn(challengeResponse) {
            try {
              const result = await confirmSignIn(challengeResponse);
              return result;
            } catch (error) {
              error.message =
                "多要素認証の設定を完了できません。無効な認証コードです。";
              throw error;
            }
          },
        }}
      >
        <App />
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          closeOnClick
          draggable
          transition={Slide}
          pauseOnHover={false}
          hideProgressBar
        />
      </Authenticator>
    </Authenticator.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
